import "./style.scss";

import { Layout } from "antd";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { actions as dashboardActions } from "@/store/dashboard/index";
import { ConversationsCard, FlowsCard, NotificationsCard, WelcomeCard } from "./components";

export default function Dashboard() {
	const intl = useIntl();
	const dispatch = useDispatch();
	const hasInboxAccess = useSelector((state) => state.dashboard.permissions.inboxAccess);

	useEffect(() => {
		dispatch(
			dashboardActions.ui.changePageTitle({
				config: {
					title: "",
					tabTitle: intl.formatMessage({ id: "welcome.page.title", defaultMessage: "Bothive" }),
				},
			})
		);
	}, []);

	return (
		<Layout.Content className="dashboard_grid" data-inbox-access={hasInboxAccess}>
			<WelcomeCard />
			<NotificationsCard />
			{hasInboxAccess && <ConversationsCard />}
			<FlowsCard />
		</Layout.Content>
	);
}
