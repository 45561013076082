import "core-js/stable";

import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { RecoilRoot } from "recoil";

import App from "./App";
import { globalHeaders } from "./helpers";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { checkForUserLanguage, checkForUserToken, initDashboardSession } from "./store/dashboard/header/header.action";
import store from "./store/store";

//eslint-disable-next-line no-undef
if (process.env.NODE_ENV === "production") {
	LogRocket.init("fkv4x9/bothive-app");
}

if (process.env.NODE_ENV !== "development") {
	// Sentry.init({
	// 	dsn: process.env.REACT_APP_SENTRY_DSN_DASHBOARD,
	// 	integrations: [
	// 	],
	// 	// Set tracesSampleRate to 1.0 to capture 100%
	// 	// of transactions for performance monitoring.
	// 	// We recommend adjusting this value in production
	// 	tracesSampleRate: 1.0,
	// 	// Capture Replay for 10% of all sessions,
	// 	// plus for 100% of sessions with an error
	// 	replaysSessionSampleRate: 0.1,
	// 	replaysOnErrorSampleRate: 1.0,
	// });
}

store.dispatch(checkForUserToken());
store.dispatch(initDashboardSession());
store.dispatch(checkForUserLanguage());
globalHeaders.setHeaders({
	language: store.getState().dashboard.header.lang,
	Authorization: store.getState().dashboard.header.token,
	organizationId: store.getState().content?.team?.team?._id,
	"organization-name": store.getState().content?.team?.team?.name,
	sessionId: store.getState().dashboard.header.sessionId,
});

ReactDOM.render(
	<Provider store={store}>
		<RecoilRoot>
				<App />
		</RecoilRoot>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
