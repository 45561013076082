import "./style.scss";

import { Card, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { icon } from "../../../assets/images";

export default function WelcomeCard() {
	const history = useHistory();
	const hasInboxAccess = useSelector((state) => state.dashboard.permissions.inboxAccess);
	const user = useSelector((state) => state.profile.account.user);
	const { team } = useParams<{ team: string }>();

	const handleCardClick = (url: string) => {
		history.push(`/${team}/${url}`);
	};

	return (
		<Card
			title={
				<Typography.Title className="dashboard-welcome-title">
					<FormattedMessage
						id="dashboard.welcome.title"
						defaultMessage="Welcome {firstName} 👋"
						values={{ firstName: user.firstName }}
					/>
				</Typography.Title>
			}
			className="dashboard-welcome"
		>
			{hasInboxAccess && <Card hoverable onClick={() => handleCardClick("inbox")} className="dashboard-welcome-card">
				<h2 className="card_body-title">
					<FormattedMessage id="dashboard.welcome.inbox" defaultMessage="Inbox" />
				</h2>
				<img src={icon.inboxIcon} className="card_body-image" />
			</Card>}
			<Card hoverable onClick={() => handleCardClick("flows")} className="dashboard-welcome-card">
				<h2 className="card_body-title">
					<FormattedMessage id="dashboard.welcome.flows" defaultMessage="Flows" />
				</h2>
				<img src={icon.flowsIcon} className="card_body-image" />
			</Card>
			<Card hoverable onClick={() => handleCardClick("contact")} className="dashboard-welcome-card">
				<h2 className="card_body-title">
					<FormattedMessage id="dashboard.welcome.contacts" defaultMessage="Contacts" />
				</h2>
				<img src={icon.contactsIcon} className="card_body-image" />
			</Card>
		</Card>
	);
}
